"use client";

import { Box, colors, Grid2 as Grid, Typography, useTheme } from "@mui/material";
import Media from "@v2/components/Media";
import MediaV2 from "@v2/components/MediaV2";

type TryContent = {
    title: string
    description: string
    media_id: string | null
    alt: string | null
};
export default function Try({ content }: {
    content?: TryContent
}) {
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Box sx={{ px: { xs: 0, sm: 3, lg2: 0 } }}>
            <Grid
                container
                sx={{
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',
                    // border: "1px solid",
                    // borderColor: colors.grey[300],
                    borderRadius: 1,
                    overflow: "hidden",
                    [theme.breakpoints.down('md')]: {
                        borderColor: 'transparent'
                    },
                    [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,

                    }
                }}
            >
                <Grid size={{ xs: 12, md: 7, lg: 7 }}>
                    <Box
                        sx={{
                            // width: "100%",
                            // height: "auto",
                            // aspectRatio: "1.5/1",
                            position: "relative",
                            // overflow: 'hidden',

                            "&:after": {
                                content: '""',
                                background: "linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%)",
                                position: "absolute",
                                height: "100%",
                                top: 0,
                                right: -2,
                                width: '40%',
                                zIndex: 2,

                                [theme.breakpoints.down('md')]: {
                                    background: "linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%)",
                                    height: '40%',
                                    width: '100%',
                                    right: 'unset',
                                    top: 'unset',
                                    bottom: -1
                                },

                            },
                        }}
                    >
                        {/* <Media id={content?.media_id} alt={content?.alt} aspectRatio="1.5 / 1" /> */}
                        <MediaV2 media_id={content?.media_id} alt={content?.alt}
                            imageProps={{
                                fill: true
                            }}
                            height={600}
                            boxProps={{
                                sx: {
                                    width: '100%',
                                    aspectRatio: "1.5 / 1"
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 5, lg: 5 }}>
                    <Box
                        sx={{
                            display: "flex",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            [theme.breakpoints.down('md')]: {
                                justifyContent: "flex-start",
                                p: 2,
                            }
                        }}
                    >
                        <Box sx={{
                            // maxWidth: 483,
                            pr: 2,
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'unset'
                            }
                        }}>
                            <Typography
                                sx={{
                                    fontSize: 40,
                                    fontWeight: 800,
                                    color: colors.grey[900],
                                    lineHeight: "70px",
                                    whiteSpace: 'pre-line',
                                    mb: 3,
                                    mt: -8,

                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: 32,
                                        lineHeight: "56px",
                                        mt: -4,

                                    },

                                    [theme.breakpoints.down('md')]: {
                                        whiteSpace: 'normal',
                                        fontSize: 28,
                                        mt: 0,

                                    },

                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: 21,
                                        textAlign: 'center',
                                        lineHeight: "36px",
                                        mb: 1,
                                        // textAlign: 'justify'
                                    }
                                }}
                            >
                                {content?.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 24,
                                    fontWeight: 400,
                                    color: colors.grey[600],
                                    whiteSpace: 'pre-line',

                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: 20,
                                        lineHeight: "38px",
                                    },


                                    [theme.breakpoints.down('md')]: {
                                        whiteSpace: 'normal',
                                        fontSize: 21,
                                        // textAlign: 'justify'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: 17,
                                        textAlign: 'center'
                                    }
                                }}
                            >
                                {content?.description}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
