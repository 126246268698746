"use client";
import { colors, Fade, NoSsr, Skeleton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import Media from "@v2/components/Media";
import FlexBox from "@v2/components/styled/FlexBox";
import TextOverflow from "@v2/components/styled/TextOverflow";
import { numberFormat } from "@v2/helpers/general";
import Image from "next/image";
import Link from "next/link";
import { Fragment } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

type CollectionSiteProduct = {
    id: number,
    slug: string,
    title: string,
    media_id: string | null
    server_data: {
        rial_price: number,
        media_id: string | null
    },
}

type Collection = {
    id: number,
    show: boolean,
    title: string,
    link: string | null,
    media_id: string | null,
    site_products: CollectionSiteProduct[],

    text_color: string,
    background_color: string,
    button_bg_color: string | null,
    button_text_color: string | null
}

interface IProps {
    reverse?: boolean;
    collection?: Collection
}

export default function Collection({ reverse, collection }: IProps) {
    const theme = useTheme()
    return (
        <Box sx={{
            px: { md: 3, lg2: 0 },
        }}>
            <Grid
                container
                sx={{
                    background: collection?.background_color ?? '#fff',
                    borderRadius: { xs: 0, md: 1 },

                    [theme.breakpoints.up('sm')]: {
                        overflow: "hidden",
                        // height: 416,
                        alignItems: 'stretch',
                    }
                }}
            >
                <Grid size={{ xs: 12, sm: 4, md: 3.5, lg: 2.7 }} sx={{
                    position: 'relative',
                    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                    zIndex: 10,
                }} >
                    <FlexBox sx={{ alignItems: 'center', height: '100%' }} >
                        <Grid container flex={1} py={{ xs: 4, sm: 0 }}>
                            <Grid size={{ xs: 12, md: 12 }} order={{ xs: 1, md: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: { md: 24, xs: 28 },
                                        fontWeight: 700,
                                        color: collection?.text_color ?? '#fff',
                                        textAlign: "center",
                                    }}
                                >
                                    {collection?.title}
                                </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 12 }} order={{ xs: 2, md: 2 }}>
                                <Box width={'100%'}>
                                    <Media height={200} objectFit="contain" id={collection?.media_id} />
                                </Box>
                            </Grid>
                            <Grid size={{ xs: 12, md: 12 }} order={{ xs: 3, md: 3 }}>
                                <FlexBox justifyContent={'center'}>
                                    <Button
                                        endIcon={
                                            <FontAwesomeIcon
                                                icon="fa-light fa-angle-left"
                                                color={collection?.button_text_color ?? "#fff"}
                                                size={13}
                                            />
                                        }
                                        variant="contained"
                                        disableElevation
                                        component={Link}
                                        href={collection?.link}
                                        sx={{
                                            backgroundColor: collection?.button_bg_color ?? undefined,
                                            color: collection?.button_text_color ?? "#fff",
                                            width: { xs: 135, md: 150 },
                                            height: 42,
                                            fontSize: { xs: 14, md: 16 },
                                        }}
                                    >
                                        مشاهده همه
                                    </Button>
                                </FlexBox>
                            </Grid>
                        </Grid>
                    </FlexBox>
                </Grid>

                <Grid size={{ xs: 12, sm: 8, md: 8.5, lg: 9.3 }}>
                    <NoSsr fallback={<LoadingSwiper />}>
                        <Box
                            sx={{
                                py: 2,
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                userSelect: 'none',
                                position: 'relative',
                                overflow: 'hidden',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    right: -1,
                                    bottom: 0,
                                    width: '1px',
                                    zIndex: 10,
                                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 1px 2px, rgba(0, 0, 0, 0.15) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 4px 8px, rgba(0, 0, 0, 0.1) 0px 8px 16px, rgba(0, 0, 0, 0.1) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

                                },
                                "& .swiper-button-prev.swiper-button-disabled": {
                                    transform: 'translateX(-200px)',
                                    transitionDelay: '300ms',
                                },
                                "& .swiper-button-next.swiper-button-disabled": {
                                    transform: 'translateX(200px)',
                                    transitionDelay: '300ms',
                                },
                                "& .swiper-button-prev, & .swiper-button-next": {
                                    transitionDelay: '0',
                                    transition: '400ms',
                                    pointerEvents: 'unset !important',
                                    marginRight: 1, marginLeft: 1,
                                    bgcolor: '#fff',
                                    height: 48, width: 48,
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    borderRadius: '50%',
                                    '&::after': {
                                        fontSize: 24, color: colors.grey[700]
                                    }
                                },
                                '& .swiper-pagination': {
                                    '& .swiper-pagination-bullet': {
                                        borderRadius: 3,
                                        transition: '180ms !important'
                                    },
                                    '& .swiper-pagination-bullet-active': {
                                        background: colors.grey[800],
                                    }
                                },
                                // px: 2,
                                // mx: 2
                                '& .swiper-wrapper': {
                                    overflow: "visible",
                                    // width: "auto",
                                    // mr: 2,
                                    // ml: 2,
                                },
                                '& .swiper': {
                                    pr: 2,
                                    pl: 2,
                                }
                            }}
                        >
                            <Swiper
                                // slidesOffsetAfter={4}
                                // slidesOffsetBefore={4}
                                // slidesPerGroup={1}
                                slidesPerView={3.5}
                                spaceBetween={8}
                                // slidesOffsetAfter={16}
                                // slidesOffsetBefore={16}
                                modules={[Navigation, Pagination]}
                                // freeMode
                                pagination={{
                                    enabled: true,
                                    dynamicBullets: true,
                                    dynamicMainBullets: 2,
                                }}
                                navigation
                                // grabCursor
                                // centeredSlides
                                breakpoints={{
                                    [theme.breakpoints.values.lg2]: {
                                        slidesPerView: 3.5
                                    },
                                    [theme.breakpoints.values.lg]: {
                                        slidesPerView: 3.2
                                    },
                                    [theme.breakpoints.values.md]: {
                                        slidesPerView: 2.5
                                    },
                                    [theme.breakpoints.values.sm]: {
                                        slidesPerView: 1.8
                                    },
                                    [theme.breakpoints.values.xs]: {
                                        slidesPerView: 1.5
                                    },

                                }}
                            >
                                {collection?.site_products.map((site_product, index) => {

                                    const isFirst = index === 0;
                                    const isLast = index === collection?.site_products.length - 1
                                    const splitTitle = site_product.title.split('-')
                                    const firstTitle = splitTitle.shift()?.trim();
                                    const secondTitle = splitTitle.join().trim();

                                    return (
                                        <SwiperSlide key={site_product.id} style={{
                                            // marginRight: isFirst ? '16px' : 0,
                                            // marginLeft: isLast ? '16px' : 0,
                                        }}>
                                            <Fade in={true} style={{
                                                transitionDelay: `${(50 * index)}ms`
                                            }}>
                                            <Box
                                                component={Link}
                                                href={'/fa/products/' + site_product.slug}
                                                sx={{
                                                    display: 'block',
                                                    background: "#fff",
                                                    borderTopLeftRadius: isFirst ? 8 : 0,
                                                    borderBottomLeftRadius: isFirst ? 8 : 0,
                                                    borderTopRightRadius: isLast ? 8 : 0,
                                                    borderBottomRightRadius: isLast ? 8 : 0,
                                                    overflow: "hidden",
                                                    transition: '350ms',
                                                    '& .collection_image': {
                                                        transition: '500ms',
                                                    },
                                                    '&:hover': {

                                                        bgcolor: colors.grey[50],

                                                        '& .collection_image': {
                                                            // transform: 'scale(1.1)'
                                                        }
                                                    }
                                                }}
                                            >
                                                <Box className="collection_image_wrapper" sx={{
                                                    width: '100%',
                                                    aspectRatio: '1 / 1',
                                                    overflow: 'hidden'
                                                }}>
                                                    <Box className="collection_image" sx={{
                                                        backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/media/${site_product.server_data.media_id}?height=400&width=520&action=cropThumbnail)`,
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center',
                                                        // height: 280,
                                                        width: '100%',
                                                        height: '100%',
                                                    }} />
                                                </Box>

                                                <Box sx={{ p: 2, pt: 0 }}>
                                                    <TextOverflow sx={{
                                                        fontSize: 16,
                                                        fontWeight: 700,
                                                        color: "#000000DE",
                                                    }}>
                                                        {firstTitle}
                                                    </TextOverflow>
                                                    <TextOverflow sx={{ direction: 'rtl', textAlign: 'right', color: colors.grey[500], fontSize: 14, mt: 1 }}>
                                                        {secondTitle}
                                                    </TextOverflow>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 1, height: 27, }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 18,
                                                                fontWeight: 700,
                                                                color: "#333",
                                                                mr: 0.5,
                                                            }}
                                                        >
                                                            {site_product.server_data.rial_price !== 0 && (
                                                                numberFormat(site_product.server_data.rial_price / 10)
                                                            )}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                color: "#00000099",
                                                            }}
                                                        >
                                                            {site_product.server_data.rial_price !== 0 ? "تــومان" : "نـامــوجـود"}

                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            </Fade>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </Box>
                    </NoSsr>

                </Grid>
            </Grid>
        </Box>
    );
}

const calc = (swiperNum: number) => (12 / swiperNum)

const LoadingSwiper = () => (
    <Grid container spacing={1} p={2} pr={2} sx={{}} >
        <Grid size={{ xs: calc(1.5), sm: calc(1.8), md: calc(2.5), lg: calc(3.2), lg2: calc(3.5) }} sx={{
            display: { xs: 'block' }
        }}> <SwiperItemSkeleton first /> </Grid>
        <Grid size={{ xs: calc(1.5), sm: calc(1.8), md: calc(2.5), lg: calc(3.2), lg2: calc(3.5) }} sx={{
            display: { xs: 'none', md: 'block' }
        }}> <SwiperItemSkeleton /> </Grid>
        <Grid size={{ xs: calc(1.5), sm: calc(1.8), md: calc(2.5), lg: calc(3.2), lg2: calc(3.5) }} sx={{
            display: { xs: 'none', lg: 'block' }
        }} > <SwiperItemSkeleton /> </Grid>
    </Grid>
)

const SwiperItemSkeleton = ({
    first, last
}: {
    first?: boolean,
    last?: boolean
}) => (
    <Box sx={{
        // aspectRatio: withoutRatio ? undefined : "34 / 47",
        position: 'relative',
        height: '100%',
        width: '100%'
    }}>
        <Box sx={{
            borderRadius: first ? "8px 0 0 8px" : null,
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            color: 'inherit',
            backgroundColor: '#ffffff',
        }}>
            {!last && (
                <Fragment>
                    <Box
                        sx={{
                            position: 'relative',
                            aspectRatio: '1 / 1',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Skeleton variant="rectangular"
                            animation="pulse"
                            sx={{
                                position: 'absolute', inset: 0, height: '100%', zIndex: 2, bgcolor: '#ffffff99',
                            }} />
                        <Image
                            height={1}
                            width={244}
                            src={"/assets/placeholder-product.jpg"}
                            alt="placeholder"
                        // objectFit="contain"
                        />
                    </Box>
                    <Box sx={{ p: 2, pt: 0 }}>
                        <Skeleton animation="wave" variant="text" sx={{ width: '80%', height: 25 }} />
                        <Skeleton animation="wave" variant="text" sx={{ height: 19, mt: 1 }} />
                        <Skeleton animation="wave" variant="text" sx={{ width: '70%', height: 28, mt: 1 }} />
                    </Box>
                </Fragment>
            )}
        </Box>
    </Box >
)
