import { Typography, TypographyProps } from "@mui/material";

// const TextOverflow = styled(Typography)({
//     whiteSpace: "nowrap",
//     textOverflow: "ellipsis",
//     overflow: "hidden",
//     display: "block",
//     // width: "100%",
// }) as typeof Typography;

// export default TextOverflow;

export default function TextOverflow(props: TypographyProps) {
    return <Typography {...props} sx={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block",
        ...props?.sx
    }} />
}
