import { Box, colors } from "@mui/material"
import { forwardRef, useState, useImperativeHandle } from "react"

const SwiperProgressBar = forwardRef((_, ref) => {
    const [percent, setPercent] = useState<number>(0)
    useImperativeHandle(ref, () => ({
        setPercent: (value: number) => setPercent(value)
    }))
    return <Box sx={{
        height: 2,
        width: '100%',
        bgcolor: colors.grey[400],
        position: 'relative',
        overflow: 'hidden'
    }}>
        <Box sx={{
            position: 'absolute',
            right: 0,
            height: 2,
            opacity: 0.6,
            bgcolor: colors.grey[900],
        }} style={{
            width: `${percent}%`,
        }} />
    </Box>
})

export default SwiperProgressBar