import { Grow, NoSsr, Skeleton, SxProps } from "@mui/material";
import { useSwiper } from "swiper/react";
import FontAwesomeIcon from "../FontAwesomeIcon";
import SwiperNavigationButton from "./SwiperNavigationButton";

const SwiperPrevButton = ({ sx }: { sx?: SxProps }) => {
    const swiperRef = useSwiper();
    const styles: SxProps = {
        bottom: 16,
        top: 'unset',
        right: { xs: 'unset', sm: 16 + 42 + 16 },
        left: { xs: 16, sm: 'unset' },
        ...sx
    }


    return <NoSsr fallback={<Skeleton variant="circular" height={42} width={42} sx={{
        position: 'absolute',
        bgcolor: '#ffffff80',
        zIndex: 2,
        ...styles,
    }} />}>
        <Grow in={true}>
            <SwiperNavigationButton sx={styles}
                color="default"
                onClick={() => {
                    swiperRef.slidePrev()
                }}>
                <FontAwesomeIcon icon="fa-light fa-angle-right" size={23} />
            </SwiperNavigationButton>

        </Grow>
    </NoSsr>
}

export default SwiperPrevButton;