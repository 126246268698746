"use client";


import { colors, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import Media from "@v2/components/Media";
import MediaV2 from "@v2/components/MediaV2";
import FlexBox from "@v2/components/styled/FlexBox";
import Link from "next/link";

type InstagramContent = {
    alt: string | null
    title: string
    media_id: string
    button_href: string
    button_text: string
    description: string
    button_bg_color: string
    button_text_color: string
}
export default function index({
    content
}: {
    content: InstagramContent
}) {
    const theme = useTheme()
    return (
        <Box
            sx={{
                px: { xs: 3, lg2: 0 },
                width: "100%",
                height: "auto",
                aspectRatio: "2.5/1",
                position: "relative",
                [theme.breakpoints.down('md')]: {
                    aspectRatio: 'unset',
                }
            }}
        >
            <Grid
                container
                sx={{
                    // border: "1px solid #0000001F",
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',

                    overflow: "hidden",
                    borderRadius: 1,
                }}
            >
                <Grid size={{ xs: 12, md: 7 }}>
                    <MediaV2
                        media_id={content?.media_id}
                        alt={content?.alt}
                        height={600}
                        imageProps={{
                            fill: true
                        }}
                        boxProps={{
                            sx: {
                                width: '100%',
                                aspectRatio: "1.5 / 1",
                                [theme.breakpoints.down('md')]: {
                                    aspectRatio: '2 / 1'
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid size={{ xs: 12, md: 5 }} sx={{
                    position: "relative",

                    "&:after": {
                        content: '""',
                        position: "absolute",
                        right: 0,
                        left: "unset",
                        width: 16,
                        height: "100%",
                        zIndex: 2,
                        background: "linear-gradient(to bottom,#833ab4,#fd1d1d,#fcb045)",
                        top: 0,

                        [theme.breakpoints.down('md')]: {
                            height: 16,
                            width: '100%',
                            right: "unset",
                            background: "linear-gradient(to left,#833ab4,#fd1d1d,#fcb045)",

                        }
                    },
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            height: "100%",
                            flexDirection: 'column',
                            justifyContent: "center",
                            alignItems: "flex-start",
                            px: 2,
                            pr: 4,
                            [theme.breakpoints.down('md')]: {
                                p: 2
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 40,
                                fontWeight: 800,
                                color: colors.grey[900],
                                mb: 3,
                                // maxWidth: 300,
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: 32
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 'unset',
                                    pt: 2,
                                    textAlign: 'center'
                                }
                            }}
                        >
                            {content?.title}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 22,
                                fontWeight: 400,
                                color: colors.grey[600],
                                mb: 5,
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: 20
                                },
                                // textAlign: 'center'
                            }}
                        >
                            {content?.description}
                        </Typography>
                        <FlexBox sx={{
                            width: 1,
                            justifyContent: 'flex-start'
                        }}>
                            <Button
                                endIcon={
                                    <FontAwesomeIcon
                                        icon="fa-light fa-angle-left"
                                        color="#fff"
                                        size={11}
                                    />
                                }
                                variant="contained"
                                // disableElevation
                                sx={{
                                    height: 48,
                                    color: content?.button_text_color,
                                    bgcolor: content?.button_bg_color
                                }}
                                {...(content?.button_href ? {
                                    component: Link,
                                    href: content.button_href,
                                    target: '_blank'
                                } : {})}
                            >
                                {content?.button_text ?? "صفحه اینستاگرام عینک وحدت"}
                            </Button>
                        </FlexBox>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
