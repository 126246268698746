import { IconButton, styled } from "@mui/material";

const SwiperNavigationButton = styled(IconButton)({
    backgroundColor: '#fff',
    zIndex: 2,
    height: 42, width: 42,
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    borderRadius: '50%',
    position: 'absolute',
    '&:hover': {
        backgroundColor: '#fff',
    }
})

export default SwiperNavigationButton;