import { Grow, NoSsr, Skeleton, SxProps } from "@mui/material";
import { useSwiper } from "swiper/react";
import FontAwesomeIcon from "../FontAwesomeIcon";
import SwiperNavigationButton from "./SwiperNavigationButton";

const SwiperNextButton = ({ sx }: { sx?: SxProps }) => {
    const swiperRef = useSwiper();
    const styles: SxProps = {
        top: 'unset',
        left: { xs: 'unset', sm: 'unset' },
        bottom: 16,
        right: 16,
        ...sx
    }

    return <NoSsr fallback={<Skeleton variant="circular" height={42} width={42} sx={{
        position: 'absolute',
        bgcolor: '#ffffff80',
        zIndex: 2,
        ...styles,
    }} />}>
        <Grow in={true}>
            <SwiperNavigationButton sx={styles}
                color="default"
                onClick={() => {
                    swiperRef.slideNext()
                }}>
                <FontAwesomeIcon icon="fa-light fa-angle-left" size={23} />
            </SwiperNavigationButton>
        </Grow>
    </NoSsr>
}

export default SwiperNextButton;