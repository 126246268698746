"use client";
import { colors, useMediaQuery, useTheme } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import MediaV2 from "@v2/components/MediaV2";
import SwiperNextButton from "@v2/components/swiper/SwiperNextButton";
import SwiperPrevButton from "@v2/components/swiper/SwiperPrevButton";
import SwiperProgressBar from "@v2/components/swiper/SwiperProgressBar";
import { useRouter } from "@bprogress/next";
import { FC, useRef } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export type SliderItem = {
    id: number;
    image: {
        id: string | null;
        sm_id: string | null;
        alt: string | null;
    };
    title: string | null;
    button_link: string | null;
    description: string | null;
    button_title: string | null;
    show_in_site: boolean;
};

const Slider = ({ content }: {
    content: SliderItem[];
}) => {
    const theme = useTheme()
    const router = useRouter();
    const autoplayProgressRef = useRef(null);

    const onAutoplayTimeLeft = (s, time, progress) => {
        autoplayProgressRef.current?.setPercent((1 - progress) * 100);
    };

    return (
        <Box sx={{
            '& .swiper-pagination': {
                bottom: { xs: 24, md: 16 },
                '& .swiper-pagination-bullet': {
                    borderRadius: 3,
                    transition: '250ms !important',
                    background: colors.grey[200],

                },
                '& .swiper-pagination-bullet-active': {
                    background: colors.grey[50],
                    width: 24,
                }
            }
        }}>
            <Swiper
                // spaceBetween={24}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                speed={450}
                autoplay={{
                    delay: 8000,
                    pauseOnMouseEnter: true
                }}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                pagination={{
                    enabled: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                }}
                loop>
                <SwiperNextButton />
                <SwiperPrevButton />
                <SwiperProgressBar ref={autoplayProgressRef} />
                {content?.map((slide) => (
                    <SwiperSlide key={slide.id}>
                        <SliderImage desktopId={slide.image.id} mobileId={slide.image.sm_id}>
                            {slide.button_title && (
                                <Box sx={{
                                    position: 'absolute',
                                    left: 16,
                                    bottom: 16,
                                    zIndex: 10,
                                    [theme.breakpoints.down('sm')]: {
                                        right: 0,
                                        left: 0,
                                        bottom: 52,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }
                                }}>
                                    <Button
                                        variant="contained"
                                        // disableElevation
                                        onClick={() => slide?.button_link && router.push(slide?.button_link)}
                                        sx={{
                                            color: colors.grey[50],
                                            bgcolor: colors.grey[900],
                                            height: 42,
                                            px: 2,
                                            fontSize: {
                                                xs: 14,
                                                md: 16,
                                            },
                                            fontWeight: 500,
                                            // width: {
                                            //     xs: 125,
                                            //     md: 165,
                                            // },
                                        }}
                                    >
                                        {slide.button_title}
                                    </Button>
                                </Box>
                            )}
                            {/* {(slide.title || slide.description) && (
                                <Grid container sx={{ height: "100%" }}>
                                    <Grid
                                        size={{ xs: 10, md: 6 }}
                                        sx={{
                                            background: {
                                                // xs: "linear-gradient(270deg, #77B9C8 51.17%, rgba(119, 185, 200, 0) 100%)",
                                                // md: "linear-gradient(270deg, rgba(113, 182, 199, 0.95) 56.96%, rgba(113, 182, 199, 0) 100%)",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                height: "100%",
                                                pl: { xs: 3, md: 11 },
                                                pt: { xs: 5, md: 9 },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#fff",
                                                    mb: 2,
                                                    fontSize: { xs: 32, md: 40 },
                                                    fontWeight: 800,
                                                }}
                                            >
                                                {slide.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#fff",
                                                    mb: { xs: 5, md: 6 },
                                                    fontSize: { xs: 14, md: 20 },
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {slide.description}
                                            </Typography>

                                        </Box>
                                    </Grid>
                                </Grid>
                            )} */}
                        </SliderImage>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

const SliderImage: FC<{ desktopId: string | null, mobileId: string | null } & BoxProps> = ({ desktopId, mobileId, ...restProps }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Box
            {...restProps}
            sx={{
                width: "100%",
                aspectRatio: '3 / 1',
                backgroundImage: desktopId ? `url('${process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/media/" + desktopId}?height=680&quality=85')` : undefined,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                [theme.breakpoints.down('sm')]: {
                    aspectRatio: '1 / 1',
                    backgroundImage: mobileId ? `url('${process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/media/" + mobileId}?height=600&quality=85')` : undefined,
                },

                // [theme.breakpoints.down("sm")]: {
                //     height: 325,
                // },

                ...restProps.sx,
            }}
        >
            {/* <MediaV2 media_id={isMobile ? mobileId : desktopId} height={640} imageProps={{
                fill: true
            }} boxProps={{
                sx: {
                    aspectRatio: '3 / 1',
                    [theme.breakpoints.down('sm')]: {
                        aspectRatio: '1 / 1',
                    },
                }
            }} options={{

            }} /> */}
        </Box>
    );
};

export default Slider;
