"use client";

import { colors, Fade, Grow, IconButton, NoSsr, Skeleton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import Logo from "@v2/components/Logo";
import MediaV2 from "@v2/components/MediaV2";
import CenterBox from "@v2/components/styled/CenterBox";
import useTouchable from "@v2/hooks/useTouchable";
import Link from "next/link";
import { Fragment, useCallback, useRef, useState } from "react";
import type { Swiper as SwiperType } from 'swiper';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

type RealsItem = {
    id: number
    alt: string | null
    show_in_site: string | null
    cover_media_id: string | null
    video_media_id: string | null
}

type RealsContentType = {
    reals: RealsItem[]
}

export default function index({
    content
}: {
    content: RealsContentType
}) {
    const theme = useTheme()

    const [playingIndex, setPlayingIndex] = useState<number | null>(null)
    const [unmutedIndex, setUnmutedIndex] = useState<number | null>(null)
    const swiperRef = useRef<SwiperType>(null)
    const videoRefs = useRef<{ [key in number]: HTMLVideoElement }>({})
    const isTouchable = useTouchable()
    const realMouseEnterTimeout = useRef(null)
    const realMouseLeaveTimeout = useRef(null)

    const onMouseEnter = useCallback((index: number) => () => {
        const activeIndex = swiperRef.current.activeIndex;
        clearTimeout(realMouseEnterTimeout.current)
        clearTimeout(realMouseLeaveTimeout.current)

        if (isTouchable) return;

        realMouseEnterTimeout.current = setTimeout(() => {

            if (index - activeIndex > 1) {
                swiperRef.current.slideTo(index - 1)
            } else if (activeIndex - index > 0) {
                swiperRef.current.slideTo(index)
            }
        }, 1100)

        const target = videoRefs.current[index]

        target.volume = 0.4
        if (unmutedIndex !== null) {
            target.muted = false;
            setUnmutedIndex(index)
        }
        target.play()
        setPlayingIndex(index)

    }, [isTouchable, unmutedIndex])
    const onMouseLeave = useCallback((index: number) => () => {
        clearTimeout(realMouseEnterTimeout.current)
        clearTimeout(realMouseLeaveTimeout.current)

        const target = videoRefs.current[index]
        target.pause()
        
        realMouseLeaveTimeout.current = setTimeout(() => {
            target.currentTime = 0;
        }, 3000)

        setPlayingIndex(null)

    }, [])

    const togglePlay = useCallback((index: number) => () => {
        const target = videoRefs.current[index]
        if (target.paused) {
            target.play()
            setPlayingIndex(index)
            target.volume = 0.4
            target.muted = true

            setUnmutedIndex(null)

            if (isTouchable) {
                target.muted = false
                setUnmutedIndex(index)
            }
        } else {
            target.pause()
            setPlayingIndex(null)
        }
    }, [isTouchable])

    const toggleMute = useCallback((index: number) => () => {
        const target = videoRefs.current[index]
        if (target.muted) {
            target.muted = false;
            setUnmutedIndex(index)
        } else {
            setUnmutedIndex(null)
            target.muted = true;
        }
    }, [])

    return (
        <Grid container sx={{ display: "flex" }}>
            <Grid size={{ md: 5.7 }}>
                <Box
                    sx={{
                        px: 4,
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{}}>
                        <Typography
                            sx={{
                                fontSize: 40, fontWeight: 800, color: colors.grey[900], mb: 3,
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 32,
                                    textAlign: 'center'

                                }
                            }}
                        >
                            با دانش عینک بخرید!
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 24,
                                fontWeight: 500,
                                color: "#00000099",
                                mb: 7,
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 18,
                                    textAlign: 'center',
                                    mb: 3
                                },
                            }}
                        >
                            ویدئوهای تخصصی به شما کمک می کنند که با دانش بیشتری عینک بخرید و
                            انتخاب مناسبی داشته باشید!
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            [theme.breakpoints.down('md')]: {
                                justifyContent: 'center',
                                mb: 3
                            }
                        }}>
                            <Button
                                variant="contained"
                                // disableElevation
                                sx={{ height: 48 }}
                                component={Link}
                                href="https://www.youtube.com/@vahdatoptic"
                                target="_blank"
                            // endIcon={<}
                            >
                                مشاهده همه ویدئوها
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6.3 }}>
                <NoSsr fallback={<LoadingSwiper />}>
                    <Box sx={{
                        '& .swiper': {
                            // pl: 2,
                            pr: 2,
                        },
                        '& .swiper-wrapper': {
                            overflow: "visible"
                        },
                        "& .swiper-button-prev.swiper-button-disabled": {
                            transform: 'translateX(-200px)',
                            // transitionDelay: '300ms',
                        },
                        "& .swiper-button-next.swiper-button-disabled": {
                            transform: 'translateX(200px)',
                            // transitionDelay: '300ms',
                        },
                        "& .swiper-button-prev, & .swiper-button-next": {
                            bottom: 16,
                            top: 'unset',
                            transitionDelay: '0',
                            transition: '400ms',
                            pointerEvents: 'unset !important',
                            marginRight: 1, marginLeft: 1,
                            bgcolor: '#fff',
                            height: 48, width: 48,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            borderRadius: '50%',
                            '&::after': {
                                fontSize: 24, color: colors.grey[700]
                            }
                        },
                    }}>
                        <Swiper
                            spaceBetween={8}
                            slidesPerView={2.5}
                            onSwiper={s => swiperRef.current = s}
                            modules={[Navigation]}
                            navigation
                        // freeMode
                        >
                            {/* <SwiperNextButton />
                            <SwiperPrevButton /> */}
                            {content?.reals?.map((realsItem, index) => {
                                const isFirst = index === 0;
                                const isLast = index === content.reals.length - 1
                                const isPlaying = playingIndex === index;
                                const hasSound = unmutedIndex === index;
                                return (
                                    <SwiperSlide key={realsItem.id}>
                                        <Fade in={true} style={{
                                            transitionDelay: `${index * 70}ms`
                                        }}>
                                            <Box
                                                onMouseEnter={onMouseEnter(index)}
                                                onMouseLeave={onMouseLeave(index)}
                                                // onClick={togglePlay(index)}
                                                className={isFirst ? 'first-child' : isLast ? 'last-child' : ''}
                                                sx={{
                                                    width: "100%",
                                                    position: "relative",
                                                    overflow: 'hidden',
                                                    aspectRatio: '9 / 16',
                                                    '&.first-child': {
                                                        borderRadius: '8px 0 0 8px',
                                                    },
                                                    '&.last-child': {
                                                        borderRadius: '0 8px 8px 0',
                                                    },
                                                    ...(!isTouchable ? {
                                                        '&:hover': {
                                                            '& .reals_controller': {
                                                                bottom: 8
                                                            }
                                                        }
                                                    } : {})
                                                }}
                                            >

                                                <CenterBox sx={{
                                                    bgcolor: colors.grey[100],
                                                    position: 'absolute',
                                                    inset: 0,
                                                    zIndex: isPlaying ? 0 : 2,
                                                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset'
                                                }}>
                                                    {realsItem.cover_media_id ? (
                                                        <MediaV2 media_id={realsItem.cover_media_id} imageProps={{
                                                            fill: true,
                                                            style: {
                                                                objectFit: 'contain'
                                                            }
                                                        }} height={520} disablePlaceholder options={{
                                                            quality: 92
                                                        }} boxProps={{
                                                            sx: {
                                                                width: '100%',
                                                                aspectRatio: ' 9 / 16'
                                                            }
                                                        }} />
                                                    ) : (
                                                        <Logo type="micro" sx={{
                                                            height: 40,
                                                        }} />
                                                    )}
                                                </CenterBox>

                                                <video width="100%" height="500"
                                                    ref={(el: any) => videoRefs.current[index] = el}
                                                    muted
                                                    loop
                                                    style={{ zIndex: 1, position: 'relative' }}
                                                    // poster={`${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/media/${realsItem.cover_media_id}?action=cropThumbnail&height=520&quality=80`}
                                                >
                                                    <source src={`${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/media/${realsItem.video_media_id}`} type="video/mp4" />
                                                </video>
                                                <Box className="reals_controller" sx={{
                                                    position: 'absolute',
                                                    transition: 'bottom 350ms',
                                                    display: 'flex',
                                                    zIndex: 5,
                                                    ...(isTouchable ? {
                                                        inset: 0,
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        ...(isPlaying ? {
                                                            backgroundColor: 'transparent',
                                                        } : {})
                                                    } : {
                                                        bottom: -60,
                                                        left: 8,
                                                        right: 8,
                                                        justifyContent: 'flex-end',
                                                    })

                                                }} onMouseEnter={e => e.stopPropagation()}>
                                                    {isTouchable ? (
                                                        <Grow in={!isPlaying} timeout={{ enter: 1000, exit: 500 }}>
                                                            <IconButton onClick={togglePlay(index)}>
                                                                <FontAwesomeIcon icon="fa-light fa-play" size={40} color="#fff" />
                                                            </IconButton>
                                                        </Grow>
                                                    ) : (

                                                        <Fragment>

                                                            <IconButton sx={{
                                                                backgroundColor: '#24242450',
                                                                '&:hover': {
                                                                    backgroundColor: '#24242490',
                                                                },
                                                                height: 42, width: 42,
                                                                zIndex: 4,
                                                                p: 0,
                                                                position: 'relative',
                                                                // pl: 2,
                                                                // fontSize: 15,
                                                                color: '#fff',
                                                                // transition: '500ms',

                                                            }} onClick={toggleMute(index)}>
                                                                <Grow in={hasSound} timeout={{ enter: 400, exit: 0 }}>
                                                                    <CenterBox sx={{ position: "absolute", inset: 0 }}>
                                                                        <FontAwesomeIcon icon="fa-light fa-volume" color="#fff" />
                                                                    </CenterBox>
                                                                </Grow>
                                                                <Grow in={!hasSound} timeout={{ enter: 400, exit: 0 }}>
                                                                    <CenterBox sx={{ position: "absolute", inset: 0 }}>
                                                                        <FontAwesomeIcon icon="fa-light fa-volume-slash" color="#fff" />
                                                                    </CenterBox>
                                                                </Grow>
                                                            </IconButton>
                                                        </Fragment>
                                                    )}

                                                </Box>
                                                {/* <Media id={realsItem.cover_media_id} alt={realsItem.alt ?? ""} aspectRatio="9 / 16" /> */}
                                            </Box>
                                        </Fade>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Box>
                </NoSsr>
            </Grid>
        </Grid >
    );
}

const LoadingSwiper = () => (
    <Grid container spacing={1} pr={2}>
        <Grid size={4.8}> <SwiperItemSkeleton first /> </Grid>
        <Grid size={4.8}> <SwiperItemSkeleton /> </Grid>
        <Grid size={2.4}> <SwiperItemSkeleton freeSize /> </Grid>
    </Grid>
)

const SwiperItemSkeleton = ({
    first,
    freeSize,
}: {
    first?: boolean,
    freeSize?: boolean
}) => (
    <Box sx={{
        aspectRatio: !freeSize ? "9 / 16" : null,
        position: 'relative',
        height: '100%'
    }}>
        <Skeleton animation="wave" variant="rectangular" sx={{
            height: '100%',
            width: '100%',
            borderRadius: first ? '8px 0 0 8px' : null
        }} />
    </Box>
)
