"use client";

import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import React from "react";
import Box from "@mui/material/Box";

export default function WhyUs() {
    return (
        <Box sx={{
            px: {xs: 2, md: 4}
        }}>
            <Grid
                container
                columnSpacing={{ md: 1, xs: 2 }}
                rowSpacing={2}
            >
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-award"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            گارانتی اصالت کالا
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            ضمانت اصل بودن تمامی محصولات
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-headset"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            راهنمایی خرید عینک و عدسی
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            از طریق تلفن و شبکه های مجازی
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-boxes-packing"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            امکان استرداد کالا
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            در صورت عدم تطابق کالا
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-clipboard-prescription"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            ارسال نسخه پزشک
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            پیشنهاد عدسی مناسب با نمره شما
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-sunglasses"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            تست رایگان در منزل
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            فقط در شهر تهران
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, xs2: 6, md: 4, lg: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon="fa-duotone fa-solid fa-truck-fast"
                            size={48}
                            color={"#BB202E"}
                        />
                        <Typography
                            sx={{
                                fontSize: { md: 16, xs: 14 },
                                fontWeight: 500,
                                color: "#333",
                                mt: 3,
                                textAlign: 'center'
                            }}
                        >
                            ارسال رایگان
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 14, xs: 12 },
                                fontWeight: 400,
                                color: "#00000099",
                                mt: 1,
                                textAlign: "center",
                            }}
                        >
                            برای خریدهای بالا ۲۰ میلیون تومان
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
