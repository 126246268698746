"use client";

import { colors, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Media from "@v2/components/Media";
import MediaV2 from "@v2/components/MediaV2";
import useTouchable from "@v2/hooks/useTouchable";
import moment from "jalali-moment";
import Link from "next/link";

type BlogType = {
    id: number,
    slug: string
    title: string
    image_id: string | null
    image_alt: string | null
    post_date: string
    show_in_site: boolean,
    post_modified: string
};
type BlogsContentType = BlogType[];
export default function LatestNews({
    content
}: {
    content: BlogsContentType
}) {
    const theme = useTheme()
    const isTouchable = useTouchable()

    return (
        <Grid
            container
            size={{ md: 10.5, xs: 12 }}
            spacing={{ md: 4, xs: 5 }}
            sx={{ px: { xs: 3, lg2: 0, } }}
        >
            <Grid size={{ xs: 12 }}>
                <Typography
                    sx={{
                        fontSize: 32,
                        fontWeight: 800,
                        color: colors.grey[900],
                        textAlign: { md: "left", xs: "center" },
                        [theme.breakpoints.down('md')]: {
                            fontSize: 28
                        },
                    }}
                >
                    آخرین دانستنی ها
                </Typography>
            </Grid>
            {content?.map((blog) => (
                <Grid key={blog.id} size={{ md: 4, xs: 12 }}>
                    <Box component={Link} href={"/fa/blog/" + blog.slug}
                        target="_blank"
                        sx={{
                            display: 'block',
                            borderRadius: 1,
                            transition: '200ms',
                            ...(isTouchable ? {
                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;"
                            } : {
                                '&:hover': {
                                    transform: 'translateY(-3px)',
                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;"
                                }
                            })
                        }}>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                            }}
                        >
                            {/* <Media id={blog.image_id} alt={blog.image_alt ?? ""} aspectRatio="16 / 9" sx={{ borderRadius: '8px 8px 0 0', overflow: 'hidden' }} /> */}
                            <MediaV2
                                media_id={blog.image_id}
                                alt={blog.image_alt ?? ""}
                                boxProps={{
                                    sx: {
                                        width: '100%',
                                        aspectRatio: "16 / 9"
                                    }
                                }}
                                options={{
                                    
                                }}
                                height={260}
                                imageProps={{
                                    fill: true
                                }}
                            />
                        </Box>
                        <Box sx={{ p: 2, pt: 0 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: "#000000DE",
                                    mt: 2,
                                    minHeight: 48,
                                }}
                            >
                                {blog.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#00000099",
                                    mt: 1,
                                }}
                            >
                                {moment(blog.post_date, 'YYYY-MM-DD HH:mm:ss').locale('fa').toNow(true)} پیش
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
            <Grid size={{ xs: 12 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        component={Link}
                        variant="contained"
                        // disableElevation
                        sx={{ width: 121, height: 48 }}
                        href={'/fa/blog/'}
                        target="_blank"
                    >
                        مطالب بیشتر
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
