"use client";

import { Box, colors, Fade, Grid2 as Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MediaV2 from "@v2/components/MediaV2";
import Link from "next/link";
import React, { forwardRef, useEffect, useState } from "react";
import { Mousewheel, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper/types";
import watermarkSrc from "~/public/assets/wm.webp";

type Category = {
    id: number;
    title: string;
    sub_categories: SubCategory[];
};

type SubCategory = {
    id: number;
    link: string | null;
    media: {
        id: string | null;
        sm_id: string | null;
        alt: string | null;
    };
    title: string;
};

interface CategoriesProps {
    categories?: Category[];
}
export default forwardRef<never, CategoriesProps>(function Categories({ categories }, ref) {
    const theme = useTheme()
    const [value, setValue] = useState(0);
    const [swiperRef, setControlledSwiper] = useState<SwiperType | null>(null);
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setLoaded(true)
    }, [])

    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

    const onChange = (event: React.SyntheticEvent, value: number) => {
        setValue(value);
        swiperRef?.slideTo(value);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: {
                        xs: 0,
                        xs2: 0.5,
                        sm: 1,
                        md: 2
                    }
                }}
            >
                <Tabs value={value} onChange={onChange} sx={{
                    width: '100%'
                }}>
                    {categories?.map((category) => (
                        <Tab
                            key={category.id}
                            label={category.title}
                            sx={{
                                fontSize: { xs: 16, sm: 18, md: 32 },
                                fontWeight: 600,
                                // transition: '200ms',
                                color: colors.grey[700],
                                "&.Mui-selected": { color: colors.grey[900] },
                                [theme.breakpoints.down('sm')]: {
                                    flex: 1,
                                    width: '100%',
                                }
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
            {loaded ? (
                <Fade in={true}>
                    <Box>
                        <Swiper
                            modules={[Mousewheel, Virtual]}
                            spaceBetween={8}
                            slidesPerView={1}
                            style={{
                                backgroundImage: `url(${watermarkSrc.src})`,
                                backgroundRepeat: 'repeat',
                                backgroundSize: '165px',
                                backgroundPosition: '0 48px',
                            }}
                            // navigation
                            onSlideChange={(slide) => {
                                setValue(slide.activeIndex);
                            }}
                            virtual
                            mousewheel={false}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                0: { spaceBetween: 4 },
                                375: { spaceBetween: 8 },
                            }}
                            // freeMode
                            onSwiper={setControlledSwiper}
                        >
                            {categories?.map((category, ci) => (
                                <SwiperSlide key={category.id} virtualIndex={ci}>
                                    <Grid container spacing={{
                                        xs: 0,
                                        sm: 0.5,
                                        md: 2
                                    }}>
                                        {category.sub_categories.map((subCategory, sbi) => (
                                            <Grid size={{ xs: 12, sm: 4 }} component={Link} href={subCategory.link} sx={{
                                                position: 'relative',
                                                '&:hover': {
                                                    // boxShadow: '4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38)'
                                                }
                                            }} key={sbi}>
                                                <MediaV2 disablePlaceholder media_id={isSmallerThanSm ? subCategory.media.sm_id : subCategory.media.id}
                                                    boxProps={{
                                                        sx: {
                                                            aspectRatio: isSmallerThanSm ? "16 / 9" : "21 / 25",
                                                            width: '100%'
                                                        }
                                                    }}
                                                    options={{
                                                        action: 'cropThumbnail'
                                                    }}
                                                    imageProps={{
                                                        style: {
                                                            objectFit: 'cover'
                                                        }
                                                    }}
                                                    width={isSmallerThanSm ? 600 : 456}
                                                    height={isSmallerThanSm ? 337 : 543}

                                                />
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        inset: 0,
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                        pb: 2,
                                                        justifyContent: "center",
                                                        background: "linear-gradient(180deg, rgba(68, 51, 51, 0) 50%, rgba(0, 0, 0, 0.5) 100%)",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                        [theme.breakpoints.down('sm')]: {
                                                            pb: 1,
                                                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.5) 100%)",
                                                        },

                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textAlign: 'center',
                                                            zIndex: 2,
                                                            fontSize: 24,
                                                            fontWeight: 600,
                                                            color: "#fff",

                                                        }}
                                                    >
                                                        {subCategory.title}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Fade>
            ) : (
                <LoadingSwiper />
            )}
        </>
    );
});

const LoadingSwiper = () => (
    <Grid container spacing={{
        xs: 0,
        sm: 0.5,
        md: 2
    }}>
        <Grid size={{ xs: 12, sm: 4 }}> <SwiperItemSkeleton /> </Grid>
        <Grid size={{ xs: 12, sm: 4 }}> <SwiperItemSkeleton /> </Grid>
        <Grid size={{ xs: 12, sm: 4 }}> <SwiperItemSkeleton /> </Grid>
    </Grid>
)

const SwiperItemSkeleton = () => {
    // const theme = useTheme()
    // const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Box sx={{
            aspectRatio: {
                xs: "16 / 9",
                sm: "21 / 25"
            },
            position: 'relative'
        }}>
            <Skeleton animation="wave" variant="rectangular" sx={{
                height: '100%',
                width: '100%'
            }} />
        </Box>
    )
}
