import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/categories/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/collection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/fashion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/instagram/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/latestNesws/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/location/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/try/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/videos/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/(pages)/fa/v2/(homepage)/components/whyUs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/components/header/index.csr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderLoading"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/components/header/loading.tsx");
