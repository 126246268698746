"use client";

import { Box, colors, Divider, Fade, Grid2 as Grid, Link as MuiLink, Slide, Typography, useMediaQuery, useTheme } from "@mui/material";
import MediaV2 from "@v2/components/MediaV2";
import TextOverflow from "@v2/components/styled/TextOverflow";
import useTouchable from "@v2/hooks/useTouchable";
import { uiColors } from "@v2/lib/color";
import Link from "next/link";
import { forwardRef, Fragment, MouseEvent, useCallback, useRef, useState } from "react";

type FashionProduct = {
    id: number;
    title: string;
    slug: string | null;
    image_id: string | null
    media_id: string | null
};
type Category = {
    id: number;
    title: string;
    products: FashionProduct[];
};
interface FashionProps {
    categories?: Category[];
}
export default forwardRef<never, FashionProps>(function Fashion({ categories }, ref) {
    const theme = useTheme()
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('md'))
    const [hoverProductId, setHoverProductId] = useState<number | null>(null)
    const isTouchable = useTouchable()

    const enterTimeout = useRef(null)
    const exitTimeout = useRef(null)


    const onMouseEnter = useCallback((product_id: number) => (event: MouseEvent<HTMLElement>) => {
        const HOVER_DELAY = 300;

        clearTimeout(enterTimeout.current)
        clearTimeout(exitTimeout.current)


        if (isTouchable) return;

        enterTimeout.current = setTimeout(() => {
            setHoverProductId(product_id)
        }, hoverProductId ? 0 : HOVER_DELAY)

    }, [isTouchable, hoverProductId])

    const onMouseLeave = useCallback((product_id: number) => (event: MouseEvent<HTMLDivElement>) => {
        const HOVEROUT_DELAY = 100;

        clearTimeout(exitTimeout.current)
        clearTimeout(enterTimeout.current)

        exitTimeout.current = setTimeout(() => {
            setHoverProductId(null)
        }, HOVEROUT_DELAY)

    }, [hoverProductId])


    return (
        <Fragment>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontSize: { xs: 32, md: 48 },
                    fontWeight: 600,
                    color: "#242424",
                }}
            >
                مد روز
            </Typography>

            <Grid container rowSpacing={4} columnSpacing={{ xs: 2, md: 4, lg: 7 }} sx={{ px: 3, py: 2 }}>
                {categories?.map((category) => (
                    <Grid container sx={{ flexDirection: "column" }} rowSpacing={1} size={{ xs: 12, md: 6 }} key={category.id}>
                        <Grid size={{ md: 12 }}>
                            {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
                            <Divider textAlign={isSmallerThanSm ? "left" : "center"} sx={{
                                '&::after, &::before': {
                                    borderColor: colors.grey[500]
                                },
                                [theme.breakpoints.down('md')]: {
                                    '&::before': {
                                        content: 'unset'
                                    },
                                },
                                [theme.breakpoints.up('md')]: {
                                    '&::after, &::before': {
                                        borderColor: 'transparent !important'
                                    },
                                }
                            }}>
                                <Typography sx={{
                                    ml: 1,
                                    fontSize: { xs: 26, md: 32 },
                                    fontWeight: 400, color: "#242424"
                                }}>
                                    {category.title}
                                </Typography>
                            </Divider>
                            {/* </Box> */}
                        </Grid>
                        <Grid container size={12} spacing={2}>
                            {category.products?.map((product, pi) => {
                                const isHover = product.id === hoverProductId
                                const split = product.title.split('-')
                                const faTitle = split.shift().trim()
                                const restTitle = split.join('-').trim()

                                return (
                                    <Grid size={{ xs: 6, sm: 4, md: 6, lg: 4, xl: 4 }} key={pi}>
                                        <Box onMouseEnter={onMouseEnter(product.id)} onMouseLeave={onMouseLeave(product.id)} sx={{
                                            borderRadius: 2,
                                            transition: '500ms',
                                            overflow: 'hidden',
                                            ...(isHover ? {

                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                                                transform: 'scale(1.05)'
                                            } : {})
                                        }}>
                                            <MuiLink sx={{ textDecoration: 'none' }} component={Link} href={'/fa/products/' + (product.slug ?? product.id)}>
                                                <MediaV2
                                                    media_id={product.media_id}
                                                    alt={product.title}
                                                    width={280}
                                                    height={200}
                                                    boxProps={{
                                                        sx: {
                                                            height: 200,
                                                            transition: '500ms',
                                                            transitionDelay: '',

                                                            ...(isHover ? {
                                                                transform: 'scale(0.82) translateY(-35px)'
                                                            } : {}),

                                                            [theme.breakpoints.down('lg')]: {
                                                                height: 140
                                                            },

                                                            // width: 200
                                                        }
                                                    }}
                                                />
                                                <Fade in={isHover} timeout={{ enter: 500, exit: 200 }}>
                                                    <Box sx={{
                                                        height: 0, px: 1,
                                                        // transitionDelay: '100ms',
                                                        transition: '500ms !important',

                                                        // position: 'relative',
                                                        ...(isHover ? {
                                                            // zIndex: 1000,
                                                            transform: 'translateY(-55px)'
                                                        } : {}),
                                                    }}>
                                                        <TextOverflow
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight: 600,
                                                                color: colors.grey[900],
                                                                mb: 1,
                                                                '&::after': {
                                                                    transition: 'ease-in 400ms',
                                                                    transitionDelay: '150ms',
                                                                    content: '""',
                                                                    position: 'absolute',
                                                                    left: 0,
                                                                    bottom: 6,
                                                                    height: 2,
                                                                    width: '0%',
                                                                    bgcolor: uiColors.primary,
                                                                    ...(isHover ? {

                                                                        width: '100%'
                                                                    } : {})
                                                                },

                                                                // mr: 0.5,
                                                            }}
                                                        >
                                                            {faTitle}
                                                        </TextOverflow>
                                                        <TextOverflow sx={{
                                                            fontSize: 10,
                                                            color: colors.grey[600],
                                                            direction: 'rtl',
                                                            textAlign: 'right'
                                                        }}>
                                                            {restTitle}
                                                        </TextOverflow>
                                                    </Box>
                                                </Fade>
                                            </MuiLink>
                                        </Box>


                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>
                ))}
            </Grid>

            {/* <FashionMd />
            <FashionXs /> */}
        </Fragment>
    );
});
