"use client";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Media from "@v2/components/Media";
import Link from "next/link";

type LocationContentType = {
    alt: string | null
    title: string | null
    media_id: string | null
    primary_button_href: string | null
    primary_button_text: string | null
    secondary_button_href: string | null
    secondary_button_text: string | null
    primary_button_bg_color: string | null
    primary_button_text_color: string | null
    secondary_button_bg_color: string | null
    secondary_button_text_color: string | null
}

export default function Location({
    content
}: {
    content?: LocationContentType
}) {
    // const theme = useTheme()
    return (
        <Box
            sx={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: { md: "auto", xs: 360 },
                aspectRatio: { md: "3/1", xs: "unset" },
                backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/media/${content?.media_id})`,
                
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                    bottom: 0,
                    inset: 0,
                    pb: { md: 5, xs: 3 },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'flex-end',
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontSize: { md: 32, xs: 18 },
                        fontWeight: 800,
                        color: "#fff",
                        mb: { md: 1, xs: 3 },
                    }}
                >
                    {content?.title}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            width: { md: 200, xs: 135 },
                            height: { md: 48, xs: 40 },
                            mr: { md: 4, xs: 1 },
                            fontSize: { md: 16, xs: 14 },
                            bgcolor: content?.primary_button_bg_color,
                            color: content?.primary_button_text_color
                        }}
                        {...(content?.primary_button_href ? {
                            component: Link as any,
                            href: content.primary_button_href,
                            target: '_blank'
                        } : {})}
                    >
                        {content?.primary_button_text}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            width: { md: 200, xs: 135 },
                            height: { md: 48, xs: 40 },
                            fontSize: { md: 16, xs: 14 },
                            bgcolor: content?.secondary_button_bg_color ?? "#fff",
                            color: content?.secondary_button_text_color ?? "#fff"
                        }}
                        {...(content?.secondary_button_href ? {
                            component: Link as any,
                            href: content?.secondary_button_href,
                            target: '_blank'
                        }: {})}
                    >
                        {content?.secondary_button_text}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
